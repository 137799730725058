import { Component, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { BaseDialogModule } from '../../../../base-dialog/base-dialog.module';
import { TranslationService } from '../../../../services/translation.service';
import { DropdownValuesService } from '../../../../services/dropdown-values.service';
import { AddressService } from '../../../../services/address.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppService } from '../../../../services/app.service';
import { LoadingService } from '../../../../services/loading.service';
import { ILicenseAddress } from '../../../../interfaces/cart.interface';
import { PortalFormGroup } from '../../../../danger-zone/form-group-override';
import { PortalFormControl } from '../../../../danger-zone/form-control-override';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Utilities } from '../../../../utilities';

export interface ILicenseAddressForm extends PortalFormGroup {
  controls: {
    AddressName: PortalFormControl;
    AddressStreet: PortalFormControl;
    AddressZipCodeNO: PortalFormControl;
    AddressTown: PortalFormControl;
    AddressCountryID: PortalFormControl;
    AddressCountryDescription: PortalFormControl;
    AddressContactFormOfAddressID: PortalFormControl;
    AddressContactFormOfAddressDescription: PortalFormControl;
    AddressContactTitleID: PortalFormControl;
    AddressContactTitleDescription: PortalFormControl;
    AddressContactFirstName: PortalFormControl;
    AddressContactLastName: PortalFormControl;
    AddressContactPhone: PortalFormControl;
    AddressContactEMailAddress: PortalFormControl;
    AddressLicenseNO: PortalFormControl;
  };
}

@Component({
  selector: 'portal-license-address-dialog',
  templateUrl: './license-address-dialog.component.html',
  styleUrls: ['./license-address-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BaseDialogModule,
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
  ],
})
export class LicenseAddressDialogComponent implements OnDestroy {
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  public addressForm: ILicenseAddressForm;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { address: ILicenseAddress },
    public translationService: TranslationService,
    public dropdownValuesService: DropdownValuesService,
    private _addressService: AddressService,
    private _dialogRef: MatDialogRef<LicenseAddressDialogComponent>,
    public appService: AppService,
    public loadingService: LoadingService
  ) {
    this.addressForm = new PortalFormGroup({
      AddressName: new PortalFormControl(this._destroyEmitter, '', Validators.required),
      AddressStreet: new PortalFormControl(this._destroyEmitter, '', Validators.required),
      AddressZipCodeNO: new PortalFormControl(this._destroyEmitter, '', Validators.required),
      AddressTown: new PortalFormControl(this._destroyEmitter, '', Validators.required),
      AddressCountryID: new PortalFormControl(this._destroyEmitter, 0, Validators.required),
      AddressCountryDescription: new PortalFormControl(this._destroyEmitter, '', Validators.required),
      AddressContactFormOfAddressID: new PortalFormControl(this._destroyEmitter, 0, Validators.required),
      AddressContactFormOfAddressDescription: new PortalFormControl(this._destroyEmitter, '', Validators.required),
      AddressContactTitleID: new PortalFormControl(this._destroyEmitter, 0),
      AddressContactTitleDescription: new PortalFormControl(this._destroyEmitter, ''),
      AddressContactFirstName: new PortalFormControl(this._destroyEmitter, '', Validators.required),
      AddressContactLastName: new PortalFormControl(this._destroyEmitter, '', Validators.required),
      AddressContactPhone: new PortalFormControl(this._destroyEmitter, '', Validators.required),
      AddressContactEMailAddress: new PortalFormControl(this._destroyEmitter, '', [Validators.required, Utilities.emailValidator]),
      AddressLicenseNO: new PortalFormControl(this._destroyEmitter, ''),
    }) as ILicenseAddressForm;

    if (this.data.address) {
      this.addressForm.reset(this.data.address);
    }
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
    this._destroyEmitter.complete();
  }

  saveAddress(): void {
    this._dialogRef.close(this.addressForm.value);
  }

  setDescriptionValue(description: string, controlName: string): void {
    this.addressForm.controls[controlName].setValue(description);
  }

  setContactTitleDescription(event: MatSelectChange): void {
    let titleDescription: string;

    if (event.value === 0) {
      titleDescription = this.translationService.translations.common.None.toString();
    } else {
      titleDescription = this.dropdownValuesService.dropdownValues$.value.Title.find((title) => title.TitleId === parseInt(event.value), 10)?.TitleDescription;
    }

    this.addressForm.controls.AddressContactTitleDescription.setValue(titleDescription);
  }
}
