<portal-base-dialog
  (primaryAction)="saveAddress()"
  [confirmButtonDescription]="translationService.translations.action.Save.toString()"
  [isConfirmButtonDisabled]="addressForm?.invalid"
  [portalIcon]="'enthus-addresses'"
  [header]="
    data.address ? translationService.translations.action.EditLicenseAddress.toString() : translationService.translations.action.AddLicenseAddress.toString()
  "
  [class.mobile]="(appService.isDesktop$ | async) === false"
>
  <div class="base-dialog-content">
    <div class="flex" [ngClass]="(appService.isMobile$ | async) === true ? 'flex-col' : 'min-w-[1000px] flex-row'">
      <div class="address-column mx-6 flex flex-col" [ngClass]="{ 'w-full': (appService.isMobile$ | async) === false }">
        <h3 class="text-centerbold">{{ translationService.translations.headers.AddressData }}</h3>

        <mat-form-field floatLabel="auto" color="accent">
          <mat-label>{{ translationService.translations.fields.AddressName1 }}</mat-label>
          <input
            matInput
            autocomplete="off"
            [placeholder]="translationService.translations.fields.AddressName1.toString()"
            [formControl]="addressForm.controls.AddressName"
            required
            [minLength]="1"
            [maxLength]="50"
          />
          <mat-icon [svgIcon]="'enthus-business'" matIconSuffix></mat-icon>
        </mat-form-field>
        <mat-form-field floatLabel="auto" color="accent">
          <mat-label>{{ translationService.translations.fields.AddressStreet }}</mat-label>
          <input
            matInput
            autocomplete="off"
            [placeholder]="translationService.translations.fields.AddressStreet.toString()"
            [formControl]="addressForm.controls.AddressStreet"
            required
            [minLength]="1"
            [maxLength]="50"
          />
        </mat-form-field>
        <mat-form-field floatLabel="auto" color="accent">
          <mat-label>{{ translationService.translations.fields.AddressTown }}</mat-label>
          <input
            matInput
            autocomplete="off"
            [placeholder]="translationService.translations.fields.AddressTown.toString()"
            [formControl]="addressForm.controls.AddressTown"
            required
            [minLength]="1"
            [maxLength]="50"
          />
        </mat-form-field>
        <mat-form-field floatLabel="auto" color="accent">
          <mat-label>{{ translationService.translations.fields.AddressZipCodeNo }}</mat-label>
          <input
            matInput
            autocomplete="off"
            [placeholder]="translationService.translations.fields.AddressZipCodeNo.toString()"
            [formControl]="addressForm.controls.AddressZipCodeNO"
            required
            [minLength]="1"
            [maxLength]="25"
          />
        </mat-form-field>
        <mat-form-field floatLabel="auto" color="accent" [attr.data-cy]="'country-form-field'">
          <mat-label>{{ translationService.translations.fields.AddressCountry }}</mat-label>
          <mat-select [formControl]="addressForm.controls.AddressCountryID" required>
            @for (country of (dropdownValuesService.dropdownValues$ | async)?.MappedCountry[translationService.locale]; track $index) {
              <mat-option [value]="country.CountryId" (onSelectionChange)="setDescriptionValue(country.CountryDescription, 'AddressCountryDescription')">
                {{ country.CountryDescription }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field floatLabel="auto" color="accent">
          <mat-label>{{ translationService.translations.fields.AddressLicenseNo }}</mat-label>
          <input
            matInput
            autocomplete="off"
            [placeholder]="translationService.translations.fields.AddressLicenseNo.toString()"
            [formControl]="addressForm.controls.AddressLicenseNO"
            [maxLength]="50"
          />
        </mat-form-field>
      </div>
      <div class="address-column mx-6 flex flex-col" [ngClass]="{ 'w-full': (appService.isMobile$ | async) === false }">
        <h3 class="bold text-center">{{ translationService.translations.headers.ContactData }}</h3>

        <mat-form-field floatLabel="auto" color="accent">
          <mat-label>{{ translationService.translations.fields.AddressContactTitle }}</mat-label>
          <mat-select [formControl]="addressForm.controls.AddressContactTitleID" (selectionChange)="setContactTitleDescription($event)">
            <mat-option [value]="0">
              {{ translationService.translations.common.None }}
            </mat-option>
            @for (title of (dropdownValuesService.dropdownValues$ | async)?.Title; track $index) {
              <mat-option [value]="title.TitleId">
                {{ title.TitleDescription }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field floatLabel="auto" color="accent">
          <mat-label>{{ translationService.translations.fields.AddressContactSalutation }}</mat-label>
          <mat-select [formControl]="addressForm.controls.AddressContactFormOfAddressID">
            @for (salutation of (dropdownValuesService.dropdownValues$ | async)?.Salutation; track $index) {
              <mat-option
                [value]="salutation.FormOfAddressId"
                (onSelectionChange)="setDescriptionValue(salutation.FormOfAddressDescription, 'AddressContactFormOfAddressDescription')"
              >
                {{ salutation.FormOfAddressDescription }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field floatLabel="auto" color="accent">
          <mat-label>{{ translationService.translations.fields.AddressContactFirstName }}</mat-label>
          <input
            matInput
            autocomplete="off"
            [placeholder]="translationService.translations.fields.AddressContactFirstName.toString()"
            [formControl]="addressForm.controls.AddressContactFirstName"
            [minLength]="1"
            [maxLength]="50"
          />
        </mat-form-field>
        <mat-form-field floatLabel="auto" color="accent">
          <mat-label>{{ translationService.translations.fields.AddressContactLastName }}</mat-label>
          <input
            matInput
            autocomplete="off"
            [placeholder]="translationService.translations.fields.AddressContactLastName.toString()"
            [formControl]="addressForm.controls.AddressContactLastName"
            [maxLength]="50"
          />
        </mat-form-field>
        <mat-form-field floatLabel="auto" color="accent">
          <mat-label>{{ translationService.translations.fields.AddressContactPhone }}</mat-label>
          <input
            matInput
            autocomplete="off"
            [placeholder]="translationService.translations.fields.AddressContactPhone.toString()"
            [formControl]="addressForm.controls.AddressContactPhone"
            [maxLength]="25"
          />
          <mat-icon [svgIcon]="'enthus-phone'" matIconSuffix></mat-icon>
        </mat-form-field>
        <mat-form-field floatLabel="auto" color="accent">
          <mat-label>{{ translationService.translations.fields.AddressContactEMailAddress }}</mat-label>
          <input
            matInput
            autocomplete="off"
            [placeholder]="translationService.translations.fields.AddressContactEMailAddress.toString()"
            [formControl]="addressForm.controls.AddressContactEMailAddress"
            [maxLength]="384"
          />
          <mat-icon [svgIcon]="'enthus-alternate_email'" matIconSuffix></mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>
</portal-base-dialog>
