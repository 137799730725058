<div class="flex flex-col">
  <div class="flex !items-start" [ngClass]="{ 'flex-col-reverse !items-end': (appService.isMobile$ | async) === true }">
    @if (!(cartService.cart$ | async).CartLicenseAddress) {
      <div class="no-selected-address-wrapper">
        <div class="no-selected-address-container edit-is-forbidden" [ngClass]="{ '!mt-[10px] w-full': (appService.isMobile$ | async) === true }">
          <mat-icon [svgIcon]="'enthus-warning'"></mat-icon>&nbsp;
          <p>{{ translationService.translations.common.NoRequiredLicenseAddress }}</p>
        </div>
      </div>
    } @else {
      <div class="w-full">
        <div class="flex justify-between" [ngClass]="{ 'flex-col-reverse': (appService.isMobile$ | async) === true }">
          <div class="w-full">
            <p>{{ (cartService.cart$ | async)?.CartLicenseAddress.AddressName }}</p>
            <p>{{ (cartService.cart$ | async)?.CartLicenseAddress.AddressStreet }}</p>
            <p>{{ (cartService.cart$ | async)?.CartLicenseAddress.AddressZipCodeNO }}</p>
            <p>{{ (cartService.cart$ | async)?.CartLicenseAddress.AddressTown }}</p>
            <p>{{ (cartService.cart$ | async)?.CartLicenseAddress.AddressCountryDescription }}</p>
            @if ((cartService.cart$ | async)?.CartLicenseAddress.AddressLicenseNO) {
              <p>{{ translationService.translations.labels.LicenseNo }}: {{ (cartService.cart$ | async)?.CartLicenseAddress.AddressLicenseNO }}</p>
            }
          </div>
          <div class="w-full">
            <p>{{ (cartService.cart$ | async)?.CartLicenseAddress.AddressContactFormOfAddressDescription }}</p>
            @if ((cartService.cart$ | async)?.CartLicenseAddress.AddressContactTitleID !== 0) {
              <p>
                {{ (cartService.cart$ | async)?.CartLicenseAddress.AddressContactTitleDescription }}
              </p>
            }
            <p>{{ (cartService.cart$ | async)?.CartLicenseAddress.AddressContactFirstName }}</p>
            <p>{{ (cartService.cart$ | async)?.CartLicenseAddress.AddressContactLastName }}</p>
            <p>{{ (cartService.cart$ | async)?.CartLicenseAddress.AddressContactEMailAddress }}</p>
            <p>{{ (cartService.cart$ | async)?.CartLicenseAddress.AddressContactPhone }}</p>
          </div>
        </div>
      </div>
    }
    <button mat-raised-button color="accent" class="thin" (click)="openLicenseAddressDialog()" [disabled]="(loadingService.isLoading$ | async) === true">
      <mat-icon [svgIcon]="'enthus-edit'"></mat-icon>&nbsp;
      {{ translationService.translations.action.Change }}
    </button>
  </div>
  <span class="sub-text-color text-clip whitespace-break-spaces text-xs">
    <br />
    {{ translationService.translations.labels.LicenseAddressHint.LicenseAddressHintFirstRow }}
    <br />
    <div class="flex flex-col">
      <div>&#9900;&nbsp;{{ translationService.translations.labels.LicenseAddressHint.LicenseCompanyName }}</div>
      <div>&#9900;&nbsp;{{ translationService.translations.labels.LicenseAddressHint.LicenseContactData }}</div>
      <div>&#9900;&nbsp;{{ translationService.translations.labels.LicenseAddressHint.LicenseContactEmailAddress }}</div>
    </div>
    <br />
    {{ translationService.translations.labels.LicenseAddressHint.LicenseAddressHintLastRow1 }}
    <a [href]="'mailto:info@enthus.de'" class="link-text-color">{{ translationService.translations.labels.LicenseAddressHint.LicenseInfoEmailText }}</a>
    {{ translationService.translations.labels.LicenseAddressHint.LicenseAddressHintLastRow2 }}
  </span>
</div>
