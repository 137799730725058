import { Component } from '@angular/core';
import { TranslationService } from '../../../services/translation.service';
import { BaseCartService } from '../../../services/base-cart.service';
import { LicenseAddressDialogComponent } from './license-address-dialog/license-address-dialog.component';
import { finalize, switchMap, take } from 'rxjs/operators';
import { ILicenseAddress } from '../../../interfaces/cart.interface';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '../../../services/loading.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'portal-checkout-license-address',
  templateUrl: './checkout-license-address.component.html',
  styleUrls: ['./checkout-license-address.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
})
export class CheckoutLicenseAddressComponent {
  constructor(
    public translationService: TranslationService,
    public cartService: BaseCartService,
    private _matDialog: MatDialog,
    public loadingService: LoadingService,
    public appService: AppService
  ) {}

  public openLicenseAddressDialog(): void {
    const dialogRef = this._matDialog.open(LicenseAddressDialogComponent, {
      data: {
        address: this.cartService.cart$.value.CartLicenseAddress,
      },
      hasBackdrop: true,
    });

    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        switchMap((address: ILicenseAddress) => {
          if (address) {
            return this.cartService.setLicenseAddress(address).pipe(finalize(() => (this.cartService.cart$.value.CartLicenseAddress = address)));
          }

          return of(null);
        })
      )
      .subscribe();
  }
}
